/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { InfomationsModel } from "../models";
import { getOverrideProps, useDataStoreDeleteAction } from "./utils";
import { schema } from "../models/schema";
import { Text, View } from "@aws-amplify/ui-react";
import { AiFillDelete, AiFillMail } from "react-icons/ai";
export default function DataLineLow(props) {
  const { infomationsModel, setIsNewFlg, overrides, ...rest } = props;
  const OnClick = useDataStoreDeleteAction({
    id: infomationsModel?.id,
    model: InfomationsModel,
    schema: schema,
  });
  const style = {
    display: "flex",
    position: "absolute",
    top: "15px",
    left: "660px",
  };
  return (
    <View
      width="728px"
      height="59px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      border="1px SOLID rgba(217,217,217,1)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "DataLineLow")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="12px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="14.522727012634277px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="153.82px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="18.64%"
        bottom="55.93%"
        left="2.51%"
        right="76.36%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={infomationsModel?.release_date}
        {...getOverrideProps(overrides, "date")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="11px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="13.3125px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="153.82px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="47.46%"
        bottom="30.51%"
        left="2.51%"
        right="76.36%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={infomationsModel?.kind}
        {...getOverrideProps(overrides, "kind")}
      ></Text>
      <Text
        style={{cursor: "pointer"}}
        fontFamily="Inter"
        fontSize="12px"
        fontWeight="400"
        color="rgba(107,139,250,1)"
        lineHeight="14.522727012634277px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="457px"
        height="42px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="13.56%"
        bottom="15.25%"
        left="23.63%"
        right="13.6%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={infomationsModel?.title}
        {...getOverrideProps(overrides, "Title")}
        onClick={() => {
          setIsNewFlg(false);
        }}
      ></Text>
      <div style={style}>
        <AiFillMail
          style={{cursor: "pointer"}}
          size="2rem"
          color="red"
          onClick={() => {
            if(confirm(process.env.REACT_APP_Notice_Email_Send_Message)){
              const headers = new Headers({
                "Content-Type": "application/json"
              });
              let postData =
                {
                  "title": infomationsModel.title,
                };
              fetch(process.env.REACT_APP_Notice_Email_Api_Url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(postData)
              })
                .then(resp=>resp.json())
                .then(result=>{ 
                  if(result === process.env.REACT_APP_Email_Success_String) {
                    alert(process.env.REACT_APP_Email_Success_Message)
                  }else{
                    alert(process.env.REACT_APP_Email_Failure_Message)
                  }
                })
            }
          }}
        />
        <AiFillDelete
          style={{cursor: "pointer"}}
          size="2rem"
          color="green"
          onClick={() => {
            if(confirm(process.env.REACT_APP_Delete_Message)){
              OnClick();
              setIsNewFlg(true);
            }
          }}
        />
      </div>
    </View>
  );
}
