/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Text, View } from "@aws-amplify/ui-react";
import { AiFillWarning, AiOutlineComment, AiFillAlert } from "react-icons/ai";
export default function DataLine(props) {
  const { infomationsModel, setIsDetailsFlg, setIsDetailsInfoFlg, setIsDetailsUpdateFlg, setIsDetailsFaultFlg, overrides, ...rest } = props;
  const style = {
    position: "absolute",
    top: "3px",
    left: "160px",
  };
  return (
    <View
      width="1000px"
      height="62px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(217,217,217,1)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "DataLine")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="19.363636016845703px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="8px"
        left="14px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={infomationsModel?.release_date}
        {...getOverrideProps(overrides, "Date")}
      ></Text>
      <div style={style}>
        {infomationsModel?.kind === "お知らせ" ? <AiOutlineComment size="2rem" color="blue"/> : <div/>}
        {infomationsModel?.kind === "メンテナンス情報" ? <AiFillWarning size="2rem" color="DarkOrange"/> : <div/>}
        {infomationsModel?.kind === "障害情報" ? <AiFillAlert size="2rem" color="red"/> : <div/>}
      </div>
      <Text
        style={{cursor: "pointer"}}
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="400"
        color="rgba(107,139,250,1)"
        lineHeight="24.204544067382812px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="789px"
        height="47px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="8px"
        left="200px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={infomationsModel?.title}
        onClick={() => {
          if(setIsDetailsFlg != undefined) setIsDetailsFlg(true);
          if(setIsDetailsInfoFlg != undefined) setIsDetailsInfoFlg(true);
          if(setIsDetailsUpdateFlg != undefined) setIsDetailsUpdateFlg(true);
          if(setIsDetailsFaultFlg != undefined) setIsDetailsFaultFlg(true);
        }}
        {...getOverrideProps(overrides, "Title")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="11px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="13.3125px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="153.82px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="50%"
        bottom="29.03%"
        left="1.4%"
        right="83.22%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={infomationsModel?.kind}
        {...getOverrideProps(overrides, "kind")}
      ></Text>
    </View>
  );
}
