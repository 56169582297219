import { useState } from 'react'
import { AiFillCloseSquare } from "react-icons/ai";

import { fetchUserAttributes } from 'aws-amplify/auth';

import {
  DataDetailsFrame
} from "../ui-components";

import {
  InquiryForm,
  DataLineCollection,
  /*DataLineCollectionInfo,
  DataLineCollectionUpdate,
  DataLineCollectionFault,*/
  DocumentList,
} from "../ui-custom-components";

import { Tabs, Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

/**
 * ユーザ用ホーム
 *
 * @returns {React.ReactElement}
 */
export default function UserHome() {
  const [detailsInfoModel, setDetailsInfoModel] = useState()
  const [detailsUpdateModel, setDetailsUpdateModel] = useState()
  const [detailsFaultModel, setDetailsFaultModel] = useState()

  const [isDetailsInfoFlg, setIsDetailsInfoFlg] = useState(false)
  const [isDetailsUpdateFlg, setIsDetailsUpdateFlg] = useState(false)
  const [isDetailsFaultFlg, setIsDetailsFaultFlg] = useState(false)

  const styles = {
    container: { width: 1024, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  }

  /**
   * タブ変更時処理
   * @param {*} tabValue 
   */
  function initDetails() {
    setIsDetailsInfoFlg(false);
    setIsDetailsUpdateFlg(false);
    setIsDetailsFaultFlg(false);
  }

  return (
      <div style={styles.container}>
      <Tabs
        defaultValue='1'
        onValueChange={()=>initDetails()}
        items={[
          {
            label: 'お知らせ',
            value: '1',
            content: (
              <>
                <Flex direction="column" gap="1.5rem">
                <div />
                  <div>
                    {isDetailsInfoFlg ?
                    <AiFillCloseSquare style={{cursor: "pointer"}} onClick={() => {
                            setIsDetailsInfoFlg(false)
                            }}/> : <div/>}
                    {isDetailsInfoFlg ? 
                    <DataDetailsFrame infomationsModel={detailsInfoModel} /> :
                    <DataLineCollection overrideItems={({ item }) => ({
                          onClick: () => {
                            setIsDetailsInfoFlg(true)
                            setDetailsInfoModel(item)
                          }
                    })} />}
                  </div>
                </Flex>
              </>
            ),
          },
          /*{
            label: '更新情報',
            value: '2',
            content: (
              <>
                <Flex direction="column" gap="1.5rem">
                  <div />
                  <div>
                    {isDetailsUpdateFlg ?
                      <AiFillCloseSquare style={{cursor: "pointer"}} onClick={() => {
                            setIsDetailsUpdateFlg(false)
                            }}/> : <div/>
                    }
                    {isDetailsUpdateFlg ? <DataDetailsFrame infomationsModel={detailsUpdateModel} /> :
                      <DataLineCollectionUpdate overrideItems={({ item }) => ({
                          onClick: () => {
                            setIsDetailsUpdateFlg(true)
                            setDetailsUpdateModel(item)
                          }
                    })} />}
                  </div>
                </Flex>
              </>
            ),
          },
          {
            label: '障害情報',
            value: '3',
            content: (
              <>
                <Flex direction="column" gap="1.5rem">
                    <div></div>
                    <div>
                    {isDetailsFaultFlg ?
                        <AiFillCloseSquare style={{cursor: "pointer"}} onClick={() => {
                              setIsDetailsFaultFlg(false)
                              }}/> : <div/>
                      }
                      {isDetailsFaultFlg ? <DataDetailsFrame infomationsModel={detailsFaultModel} /> :
                      <DataLineCollectionFault overrideItems={({ item }) => ({
                            onClick: () => {
                              setIsDetailsFaultFlg(true)
                              setDetailsFaultModel(item)
                            }
                      })} />}
                    </div>
                  </Flex>
              </>
            ),
          },*/
          {
            label: 'ダウンロード',
            value: '4',
            content: (
              <>
                <div></div>
                <div>
                  <DocumentList />
                </div>
              </>
            ),
          },
          {
            label: '問い合わせ',
            value: '5',
            content: (
              <>
                <InquiryForm onSubmit={fields=> {
                      const headers = new Headers({ "Content-Type": "application/json" });
                      const apiUrl = process.env.REACT_APP_Email_Api_Url;
                      async function getAttributes() {
                        try {
                          let attributes = await fetchUserAttributes();
                          return attributes;
                        }
                        catch (error) {
                          console.log(error);
                        }
                      };
                      getAttributes().then(result => {
                        let postData =
                          {
                            "name": result.given_name,
                            "email": result.email,
                            "company": result.locale,
                            "text": fields.text,
                          };
                        fetch(apiUrl, {
                          method: 'POST',
                          headers: headers,
                          body: JSON.stringify(postData)
                        })
                          .then(resp=>resp.json())
                          .then(result=>{ 
                            if(result === process.env.REACT_APP_Email_Success_String) {
                              alert(process.env.REACT_APP_Email_Success_Message)
                            }else{
                              alert(process.env.REACT_APP_Email_Failure_Message)
                            }
                          })
                      });
                    }} />
              </>
            ),
          },
        ]}
      />
      </div>
  );
}