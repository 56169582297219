import { useState } from 'react'
import { AiFillCloseSquare } from "react-icons/ai";

import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

import {
  DataDetailsFrame
} from "../ui-components";

import {
  InquiryForm,
  DataLineCollection,
  DataLineLowCollection,
  DocumentUploadForm,
  DocumentList,
  InfomationsModelCreateForm,
  InfomationsModelUpdateForm,
} from "../ui-custom-components";

import { Tabs, Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

/**
 * 管理者用ホーム
 *
 * @returns {React.ReactElement}
 */
export default function ManageHome(props) {
  const [detailsInfoModel, setDetailsInfoModel] = useState()
  const [detailsUpdateModel, setDetailsUpdateModel] = useState()
  const [detailsFaultModel, setDetailsFaultModel] = useState()
  const [updateFormInfo, setUpdateFormInfo] = useState()

  const [isNewFlg, setIsNewFlg] = useState(true)
  const [isDetailsFlg, setIsDetailsFlg] = useState(false)
  const [isDetailsInfoFlg, setIsDetailsInfoFlg] = useState(false)
  const [isDetailsUpdateFlg, setIsDetailsUpdateFlg] = useState(false)
  const [isDetailsFaultFlg, setIsDetailsFaultFlg] = useState(false)

  const [documentRenderFlg, setDocumentRenderFlg] = useState(false)

  const styles = {
    container: { width: 1024, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  }

  /**
   * タブ変更時処理
   * @param {*} tabValue 
   */
  function initDetails(tabValue) {
    setIsDetailsFlg(false);
    setIsDetailsInfoFlg(false);
    setIsDetailsUpdateFlg(false);
    setIsDetailsFaultFlg(false);
    if(tabValue==4)
      setDocumentRenderFlg(true);
    else
      setDocumentRenderFlg(false);
  }

  return (
      <div style={styles.container}>
      <Tabs
        defaultValue='1'
        onValueChange={(value)=>{
          initDetails(value);
        }}
        items={[
          {
            label: 'お知らせ',
            value: '1',
            content: (
              <>
                <Flex direction="column" gap="1.5rem">
                <div />
                  <div>
                    {isDetailsFlg ?
                    <AiFillCloseSquare color={'black'} size="1rem" style={{cursor: "pointer"}} onClick={() => {
                            setIsDetailsFlg(false)
                            }}/> : <div/>}
                    {isDetailsFlg ? 
                    <DataDetailsFrame infomationsModel={detailsInfoModel} /> :
                    <DataLineCollection setIsDetailsFlg={setIsDetailsFlg} overrideItems={({ item }) => ({
                          onClick: () => {
                            setDetailsInfoModel(item)
                          }
                    })} />}
                  </div>
                </Flex>
              </>
            ),
          },
          /*{
            label: '更新情報',
            value: '2',
            content: (
              <>
                <Flex direction="column" gap="1.5rem">
                  <div />
                  <div>
                    {isDetailsUpdateFlg ?
                      <AiFillCloseSquare style={{cursor: "pointer"}} onClick={() => {
                            setIsDetailsUpdateFlg(false)
                            }}/> : <div/>
                    }
                    {isDetailsUpdateFlg ? <DataDetailsFrame infomationsModel={detailsUpdateModel} /> :
                      <DataLineCollectionUpdate setIsDetailsUpdateFlg={setIsDetailsUpdateFlg} overrideItems={({ item }) => ({
                          onClick: () => {
                            setDetailsUpdateModel(item)
                          }
                    })} />}
                  </div>
                </Flex>
              </>
            ),
          },
          {
            label: '障害情報',
            value: '3',
            content: (
              <>
                <Flex direction="column" gap="1.5rem">
                    <div></div>
                    <div>
                      {isDetailsFaultFlg ?
                        <AiFillCloseSquare style={{cursor: "pointer"}} onClick={() => {
                              setIsDetailsFaultFlg(false)
                              }}/> : <div/>
                      }
                      {isDetailsFaultFlg ? <DataDetailsFrame infomationsModel={detailsFaultModel} /> :
                        <DataLineCollectionFault setIsDetailsFaultFlg={setIsDetailsFaultFlg} overrideItems={({ item }) => ({
                            onClick: () => {
                              setDetailsFaultModel(item)
                            }
                        })} />}
                    </div>
                  </Flex>
              </>
            ),
          },*/
          {
            label: 'ダウンロード',
            value: '4',
            content: (
              <>
                <div></div>
                <div>
                {documentRenderFlg? <DocumentList/> : <div/> }
                </div>
              </>
            ),
          },
          {
            label: '問い合わせ',
            value: '5',
            content: (
              <>
                <InquiryForm onSubmit={fields=> {
                      const headers = new Headers({ "Content-Type": "application/json" });
                      async function getAttributes() {
                        try {
                          let attributes = await fetchUserAttributes();
                          return attributes;
                        }
                        catch (error) {
                          alert(process.env.REACT_APP_Email_Failure_Message);
                        }
                      };
                      getAttributes().then(result => {
                        let postData =
                          {
                            "name": result.given_name,
                            "email": result.email,
                            "company": result.locale,
                            "text": fields.text,
                          };
                        fetch(process.env.REACT_APP_Inquiry_Email_Api_Url, {
                          method: 'POST',
                          headers: headers,
                          body: JSON.stringify(postData)
                        })
                          .then(resp=>resp.json())
                          .then(result=>{ 
                            if(result === process.env.REACT_APP_Email_Success_String) {
                              alert(process.env.REACT_APP_Email_Success_Message)
                            }else{
                              alert(process.env.REACT_APP_Email_Failure_Message)
                            }
                          })
                          .catch(error => {
                            alert(process.env.REACT_APP_Email_Failure_Message);
                          });
                      });
                    }} />
              </>
            ),
          },
          {
            label: 'お知らせ編集',
            value: '6',
            content: (
              <>
                <Flex direction="row" gap="2rem">
                  <div>{
                    isNewFlg ? <InfomationsModelCreateForm /> : 
                    <InfomationsModelUpdateForm infomationsModel={updateFormInfo} 
                      onCancel={()=>setIsNewFlg(true)} />
                  }</div>
                  <Flex direction="column" gap="1.5rem">
                      <div></div>
                      <div>
                      <DataLineLowCollection setIsNewFlg={setIsNewFlg} overrideItems={({ item }) => ({
                        onClick: () => {
                          setUpdateFormInfo(item)
                        }
                      })} />
                      </div>
                  </Flex>
                </Flex>
              </>
            ),
          },
          {
            label: 'ドキュメント編集',
            value: '7',
            content: (
              <>
                <div><DocumentUploadForm /></div>
              </>
            ),
          },
        ]}
      />
      </div>
  );
}