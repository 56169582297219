/**
 * ドキュメントリスト
 *
 * @returns {React.ReactElement}
 */
import { list, getUrl } from 'aws-amplify/storage';
import { useEffect, useState } from "react";
import { AiFillFilePdf } from "react-icons/ai";
import { Flex, Table, TableCell, TableRow } from '@aws-amplify/ui-react';

  /**
  * Amplifyと連携しているS3からリストオブジェクト生成
  */
export default function DocumentList() {
  const [s3DownloadLinks, setS3DownloadLinks] = useState([]);

  async function listObjectsFromS3() {
    const s3List = await list({
      options:  {
        accessLevel: process.env.REACT_APP_S3_Access_Level,
      }
    });

    s3List.items.forEach( async (item)  => {
      if(item.key.length > 0) {
        let downloadLink = await generateDownloadLinks(item.key);
        setS3DownloadLinks((s3DownloadLinks) => [
          ...s3DownloadLinks,
          downloadLink,
        ]);
      }
    });
  }

  /**
   * ダウンロード用リンクタグ生成
   * @property {string} filekey
   * @returns {HtmlElement}
   */
  async function generateDownloadLinks(fileKey) {
    const result = await getUrl({
      key: fileKey
    });
    return downloadBlob(result.url, fileKey);
  }

  /**
   * ダウンロード用リンクタグ生成
   * @property {blob} blob
   * @property {String} filekey
   * @returns {HtmlElement}
   */
  async function downloadBlob(url, fileKey) {
    const link = document.createElement("a");
    link.href = url
    link.download = fileKey
    return link;
  }

  useEffect(() => { listObjectsFromS3(); }, []);

  return (
    <div>
      <Flex direction="column">
        <Table caption="各種ドキュメント" size="small">
          {s3DownloadLinks.map((item, index) => (
            <TableRow>
              <TableCell>
                <AiFillFilePdf color="red" size="1.2rem" />
                <a href={item.href} target="_blank" download={item.download}>
                  {item.download}
                </a>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Flex>
    </div>
  );
}