import React, { useState, useEffect } from 'react'

import { Amplify } from 'aws-amplify';
import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';

import { I18n } from 'aws-amplify/utils';

import { ManageHome, UserHome } from './ui-custom-components';

import { Authenticator, translations } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import logo from "./img/logo.png";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

// サインイン画面の日本語化
translations.SIGN_IN_HEADER_TEXT = "CEAMSサポートサイト";
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

/**
 * アプリケーションメイン制御
 *
 * @returns {React.ReactElement}
 */
function App() {

  /**
   * 管理者判定
   * @param {AuthUser}
   * @returns {boolean}
   */
  function managerVerification(user) {
    const reg = new RegExp(process.env.REACT_APP_Manager_ID_Pattern);
    try {
      return reg.test(user.signInDetails.loginId);
    }
    catch(error) {
      console.error(error);
    }
    return false;
  }

  return (
    <Authenticator hideSignUp={true}>
        {({ signOut, user }) => (
          <div>
            <header className="sticky-top bg-dark text-white">
              <nav className="navbar navbar-expand-lg px-3">
                <a className="navbar-brand" href=".">
                  <img src={logo} alt="CEAMSロゴ" width="100"/>
                </a>
                <span className="navbar-brand mb-0 h1">サポートサイト</span>
                <button className="btn btn-outline-secondary" onClick={signOut}>サインアウト</button>
              </nav>
            </header>
            <div>
            {
                managerVerification(user) ? (<ManageHome />):(<UserHome />)
            }
            </div>
          </div>
        )}
      </Authenticator>
  )
}

export default App