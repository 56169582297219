/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { InfomationsModel } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function InfomationsModelCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    release_date: "",
    kind: "",
    title: "",
    text: "",
  };
  const [release_date, setRelease_date] = React.useState(
    initialValues.release_date
  );
  const [kind, setKind] = React.useState(initialValues.kind);
  const [title, setTitle] = React.useState(initialValues.title);
  const [text, setText] = React.useState(initialValues.text);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setRelease_date(initialValues.release_date);
    setKind(initialValues.kind);
    setTitle(initialValues.title);
    setText(initialValues.text);
    setErrors({});
  };
  const validations = {
    release_date: [],
    kind: [],
    title: [{ type: "Required" }],
    text: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          release_date,
          kind,
          title,
          text,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new InfomationsModel(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "InfomationsModelCreateForm")}
      {...rest}
    >
      <TextField
        label="公開日"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={release_date}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              release_date: value,
              kind,
              title,
              text,
            };
            const result = onChange(modelFields);
            value = result?.release_date ?? value;
          }
          if (errors.release_date?.hasError) {
            runValidationTasks("release_date", value);
          }
          setRelease_date(value);
        }}
        onBlur={() => runValidationTasks("release_date", release_date)}
        errorMessage={errors.release_date?.errorMessage}
        hasError={errors.release_date?.hasError}
        {...getOverrideProps(overrides, "release_date")}
      ></TextField>
      <SelectField
        label="種別"
        placeholder="--種別を選んでください--"
        isDisabled={false}
        value={kind}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              release_date,
              kind: value,
              title,
              text,
            };
            const result = onChange(modelFields);
            value = result?.kind ?? value;
          }
          if (errors.kind?.hasError) {
            runValidationTasks("kind", value);
          }
          setKind(value);
        }}
        onBlur={() => runValidationTasks("kind", kind)}
        errorMessage={errors.kind?.errorMessage}
        hasError={errors.kind?.hasError}
        {...getOverrideProps(overrides, "kind")}
      >
        <option
          children="お知らせ"
          value="お知らせ"
          {...getOverrideProps(overrides, "kindoption0")}
        ></option>
        <option
          children="メンテナンス情報"
          value="メンテナンス情報"
          {...getOverrideProps(overrides, "kindoption1")}
        ></option>
        <option
          children="障害情報"
          value="障害情報"
          {...getOverrideProps(overrides, "kindoption2")}
        ></option>
      </SelectField>
      <TextField
        label="タイトル"
        isRequired={true}
        isReadOnly={false}
        value={title}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              release_date,
              kind,
              title: value,
              text,
            };
            const result = onChange(modelFields);
            value = result?.title ?? value;
          }
          if (errors.title?.hasError) {
            runValidationTasks("title", value);
          }
          setTitle(value);
        }}
        onBlur={() => runValidationTasks("title", title)}
        errorMessage={errors.title?.errorMessage}
        hasError={errors.title?.hasError}
        {...getOverrideProps(overrides, "title")}
      ></TextField>
      <TextAreaField
        label="内容"
        isRequired={true}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              release_date,
              kind,
              title,
              text: value,
            };
            const result = onChange(modelFields);
            value = result?.text ?? value;
          }
          if (errors.text?.hasError) {
            runValidationTasks("text", value);
          }
          setText(value);
        }}
        onBlur={() => runValidationTasks("text", text)}
        errorMessage={errors.text?.errorMessage}
        hasError={errors.text?.hasError}
        {...getOverrideProps(overrides, "text")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="作成"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
