/**
 * ドキュメントアップロードフォーム
 *
 * @returns {React.ReactElement}
 */
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { list, getUrl, remove } from 'aws-amplify/storage';
import { useEffect, useState } from "react";
import { AiFillDelete, AiFillFilePdf } from "react-icons/ai";
import { Flex, Table, TableCell, TableRow } from '@aws-amplify/ui-react';

export default function DocumentUploadForm() { 
    const acceptedFileTypes = [process.env.REACT_APP_S3_Document_Type];
    const accessLevel = process.env.REACT_APP_S3_Access_Level;
    const maxFileCount = 1;
    const [s3DownloadLinks, setS3DownloadLinks] = useState([]);
    const [count, setCount] = useState(0);

    async function listObjectsFromS3() {
        const s3List = await list({ 
          options:  {
            accessLevel: process.env.REACT_APP_S3_Access_Level,
          }
        });
    
        s3List.items.forEach( async (item)  => {
          if(item.key.length > 0) {
            let downloadLink = await generateDownloadLinks(item.key);
            setS3DownloadLinks((s3DownloadLinks) => [
              ...s3DownloadLinks,
              downloadLink,
            ]);
          }
        })
    }
    
    /**
    * ダウンロード用リンクタグ生成
    * @property {string} filekey
    * @returns {HtmlElement}
    */
    async function generateDownloadLinks(fileKey) {
        const result = await getUrl({
            key: fileKey
        });
        return downloadBlob(result.url, fileKey);
    }
    
    /**
    * ダウンロード用リンクタグ生成
    * @property {blob} blob
    * @property {String} filekey
    * @returns {HtmlElement}
    */
    async function downloadBlob(url, fileKey) {
        const link = document.createElement("a");
        link.href = url
        link.download = fileKey
        return link;
    }
    
    useEffect(() => { listObjectsFromS3(); }, [count]);

    return (
        <div>
            <div>
                <StorageManager
                    acceptedFileTypes={acceptedFileTypes}
                    accessLevel={accessLevel}
                    maxFileCount={maxFileCount}
                    displayText={{
                        dropFilesText: 'ここにドラッグ＆ドロップしてください',
                        browseFilesText: 'ファイルを選択',
                    }}
                    onUploadSuccess={() => {
                        alert(process.env.REACT_APP_Save_Message);
                        setS3DownloadLinks([]);
                        setCount(count+1);
                    }}
                />
            </div>
            <div>
                <Flex direction="column">
                    <Table caption="各種ドキュメント" size="small">
                        {s3DownloadLinks.map((item, index) => (
                            <TableRow>
                                <TableCell>
                                    <AiFillFilePdf color="red" size="1.2rem" />
                                        <a href={item.href} target="_blank" download={item.download}>
                                        {item.download}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <AiFillDelete
                                        style={{cursor: "pointer"}}
                                        size="2rem"
                                        color="green"
                                        onClick={ async () => {
                                            if( window.confirm(process.env.REACT_APP_Delete_Message) ) {
                                                try {
                                                    await remove({ key: item.download });
                                                    alert(process.env.REACT_APP_Delete_Success_Message);
                                                } catch (error) {
                                                    alert(process.env.REACT_APP_Delete_Failure_Message);
                                                    console.log('Error ', error);
                                                }
                                                setS3DownloadLinks([]);
                                                setCount(count + 1);
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Flex>
            </div>
        </div>
    )
}